<template>
    <div class="page-customer-billing">
        <div class="row">
            <div class="col-8 col-tab-12">
                <app-table
                    class="payment-methods-table"

                    title="Payment methods"
                    description="Add and manage payment methods"

                    :cols="cols"
                    :rows="rows"

                    :loading="loading || processing"

                    @make-default="makeDefaultPaymentMethod"
                    @delete="deletePaymentMethod"
                />
            </div>

            <div class="col-4 col-tab-12">
                <app-adding-banner
                    header="Add credit card"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit"

                    image="credit-card"
                    
                    action="adding"

                    @adding="dialogs.adding.show = true"
                />
            </div>
        </div>



        <dialog-adding-credit-card
            v-if="dialogs.adding.show"
            v-model="dialogs.adding.show"

            :uuid="customer_uuid"
        ></dialog-adding-credit-card>
    </div>
</template>

<script>
import appTable from '@/components/app-table'

import appAddingBanner from '@/components/app-adding-banner'
import dialogAddingCreditCard from './dialogs/dialog-adding-credit-card'

const TEST_PAYMENT_METHODS = [
    {
        CardExpiredAfterNanos: 0,
        CardExpiryMonth: 4,
        CardExpiryYear: 2044,
        CardLast4: "4444",
        CardName: "",
        CardType: "MasterCard",
        CreatedAtNanos: 0,
        CustomerUUID: "8999a995-1c89-4cd1-ab00-7e3b89688831",
        DeletedAtNanos: 0,
        LastErrorMessage: "",
        LastErrorNanos: 0,
        LastUsedNanos: 1595241530543618000,
        Nonce: "",
        PaymentProvider: "STRIPE",
        PaymentProviderCustomerID: "cus_HgJ0uP2owXuvs9",
        Rank: 0,
        SPID: 1,
        Token: "",
        UUID: "cac59b1a-9963-44ff-80fe-3b85652b66da",
        UpdatedAtNanos: 0,

        IsDefault: true,
    }, {
        CardExpiredAfterNanos: 0,
        CardExpiryMonth: 4,
        CardExpiryYear: 2024,
        CardLast4: "4242",
        CardName: "",
        CardType: "Visa",
        CreatedAtNanos: 0,
        CustomerUUID: "8999a995-1c89-4cd1-ab00-7e3b89688831",
        DeletedAtNanos: 0,
        LastErrorMessage: "",
        LastErrorNanos: 0,
        LastUsedNanos: 1595340776905175800,
        Nonce: "",
        PaymentProvider: "STRIPE",
        PaymentProviderCustomerID: "cus_HgJ0uP2owXuvs9",
        Rank: 0,
        SPID: 1,
        Token: "",
        UUID: "8b9e47c6-43e3-4bf7-baa7-0f958f79e035",
        UpdatedAtNanos: 0,
    }, {
        CardExpiredAfterNanos: 0,
        CardExpiryMonth: 4,
        CardExpiryYear: 2055,
        CardLast4: "5556",
        CardName: "",
        CardType: "Visa",
        CreatedAtNanos: 0,
        CustomerUUID: "8999a995-1c89-4cd1-ab00-7e3b89688831",
        DeletedAtNanos: 0,
        LastErrorMessage: "",
        LastErrorNanos: 0,
        LastUsedNanos: 1595341006776383500,
        Nonce: "",
        PaymentProvider: "STRIPE",
        PaymentProviderCustomerID: "cus_HgJ0uP2owXuvs9",
        Rank: 0,
        SPID: 1,
        Token: "",
        UUID: "c6b98999-7a53-4d1e-8e52-694d55facdb3",
        UpdatedAtNanos: 0,
    }, {
        CardExpiredAfterNanos: 0,
        CardExpiryMonth: 3,
        CardExpiryYear: 2028,
        CardLast4: "0005",
        CardName: "",
        CardType: "American Express",
        CreatedAtNanos: 0,
        CustomerUUID: "8999a995-1c89-4cd1-ab00-7e3b89688831",
        DeletedAtNanos: 0,
        LastErrorMessage: "",
        LastErrorNanos: 0,
        LastUsedNanos: 1595340897654267600,
        Nonce: "",
        PaymentProvider: "STRIPE",
        PaymentProviderCustomerID: "cus_HgJ0uP2owXuvs9",
        Rank: 0,
        SPID: 1,
        Token: "",
        UUID: "1d1a0bbe-560a-4811-821d-99b9a2fb01a2",
        UpdatedAtNanos: 0,
    }, {
        CardExpiredAfterNanos: 0,
        CardExpiryMonth: 4,
        CardExpiryYear: 2044,
        CardLast4: "0505",
        CardName: "",
        CardType: "JCB",
        CreatedAtNanos: 0,
        CustomerUUID: "8999a995-1c89-4cd1-ab00-7e3b89688831",
        DeletedAtNanos: 0,
        LastErrorMessage: "",
        LastErrorNanos: 0,
        LastUsedNanos: 1595340952502134300,
        Nonce: "",
        PaymentProvider: "STRIPE",
        PaymentProviderCustomerID: "cus_HgJ0uP2owXuvs9",
        Rank: 0,
        SPID: 1,
        Token: "",
        UUID: "0c7b8269-5a7d-4fb8-b4da-bd2df4b89853",
        UpdatedAtNanos: 0,
    }
]

export default {
    components: {
        appTable,

        appAddingBanner,
        dialogAddingCreditCard,
    },

    data() {
        return {
            cols: [
                { key: 'type',    type: 'image'                                              },
                { key: 'last4',                                                              },
                { key: 'expiry',                  behavior: 'detached', detached_position: 2 },
                { key: 'default', type: 'status', behavior: 'optional'                       },
                { actions: [
                    { action: 'make-default', title: 'Make default'                 },
                    { action: 'delete',       title: 'Delete',      class: 'danger' },
                ], behavior: 'detached'},
            ],

            rows: [],

            dialogs: {
                adding: {
                    show: false,
                }
            },

            loading: false,
            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.customer_uuid) {
                this.getPaymentMethods()
            } else {
                this.rows = []
            }
        },

        getPaymentMethods() {
            this.loading = true

            setTimeout(() => {
                this.rows = TEST_PAYMENT_METHODS.map(card => {
                    const type = card.CardType.trim().toLowerCase().replace(/\s/g, '')
                    
                    const row = {
                        uuid: card.UUID,
                        type: {
                            text: card.CardType,
                            class: `card-icon card-${ type }`
                        },
                        last4: `XXXX-XXXX-XXXX-${ card.CardLast4 }`,
                        expiry: `${ (card.CardExpiryMonth.length > 1 ? '' : '0') + card.CardExpiryMonth }/${ card.CardExpiryYear }`,
                        default: false,
                    }

                    if (card.IsDefault) {
                        row.default = {
                            status: 'danger',
                            text: 'Default',
                        }
                    }

                    return row
                })

                this.loading = false
            }, 500)
        },

        makeDefaultPaymentMethod(card) {
            const index = this.rows.findIndex(row => row.uuid == card.uuid)
            const index_default = this.rows.findIndex(row => row.default)

            if (index > -1 && index != index_default) {
                this.processing = true

                setTimeout(() => {
                    if (index_default > -1) {
                        this.rows[index_default].default = false
                    }
                    
                    this.rows[index].default = {
                        status: 'danger',
                        text: 'Default',
                    }

                    this.processing = false
                }, 500)
            }
        },

        deletePaymentMethod(card) {
            const index = this.rows.findIndex(row => row.uuid == card.uuid)

            if (index > -1) {
                this.processing = true

                setTimeout(() => {
                    this.rows.splice(index, 1)

                    this.processing = false
                }, 500)
            }
        },
    },

    computed: {
        customer_uuid() {
            return this.$route.params.uuid
        },
    },
}
</script>

<style lang="scss">
.page-customer-billing {
    .row {
        .col-6,
        .col-12 {
            margin-bottom: 30px;
        }

        &:first-child,
        &:last-child {
            background-color: transparent;

            .col-6,
            .col-12 {
                margin-bottom: 0;
            }
        }
    }

    .payment-methods-table {
        margin-bottom: 30px;

        @include table-cols-width((200px, 175px, 90px, 100px, 50px), true, 1);

        .card-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 32px;
            margin-right: 16px;

            &:before {
                content: "";
                width: 100%;
                height: 100%;
                background: url(./images/default.svg) no-repeat center;
                background-size: contain;
            }

            @each $card-type in ('visa', 'mastercard', 'americanexpress', 'discover', 'dinersclub', 'jcb', 'unionpay') {
                &.card-#{ $card-type } {
                    &:before {
                        background-image: url('./images/#{ $card-type }.svg');
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-customer-billing {
        .payment-methods-table {
            @include table-cols-width((200px, 145px, 75px, 75px, 48px), true, 1);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-customer-billing {
        .payment-methods-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((215px, 60px), true);
        }

        .app-adding-banner {
            margin-top: 15px;
        }
    }
}
</style>