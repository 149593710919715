<template>
    <app-dialog class="dialog-adding-credit-card"
        v-if="show"
        v-model="show"
        
        :max-width="maxWidth"

        :errors="errors"

        :loading="loading"
        :processing="processing"

        @closes="closes"
    >
        <template #head>
            <h2 class="heading">Add credit card</h2>
        </template>

        <template #form>
            <div>
                <app-input :value="customer.Email" label="Customer e-mail" readonly></app-input>
                <button class="btn btn-primary" :disabled="!customer.Email" @click="send('email')">Send Email</button>
            </div>

            <div>
                <app-input :value="customer.MobileNumber" label="Customer mobile number" readonly :disabled="!customer.MobileNumber"></app-input>
                <button class="btn btn-primary" :disabled="!customer.MobileNumber" @click="send('sms')">Send TXT</button>
            </div>
        </template>
    </app-dialog>
</template>


<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'

import appInput from '@/components/app-input'
import appInvitedMembers from '@/views/team/components/app-invited-members'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:    { required: true },
        uuid:     { required: true },

        maxWidth: { type: [ Number, String ], default: 770 },
    },

    components: {
        appDialog,

        appInput,
        appInvitedMembers,
    },

    data() {
        return {
            customer: {},

            errors: {
                show: false,
                message: '',
            },

            loading: false,
            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadCustomerInfo()
        },

        loadCustomerInfo() {
            this.loading = true

            this.$store.dispatch('getCustomerByUUID', { uuid: this.uuid }).then(customer => {
                this.customer = customer

                this.loading = false
            }).catch(error => {
                this.errors.show = true
                this.errors.message = errMessage(error)

                this.loading = false
            })
        },

        close() {
            this.show = false
        },

        closes(with_button) {
            this.$emit('closes', with_button)
        },

        send(target) {
            this.processing = true

            setTimeout(() => {
                this.processing = false
                this.close()
            }, 500)
        },
    },

    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-adding-credit-card {
    z-index: $z-index-dialog-upmost;

    .app-dialog-container {
        flex-shrink: 0;
        height: auto;
        max-height: none;

        .app-dialog-body {
            padding: 80px;

            form {
                margin: 0;

                &>div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .app-input {
                        width: 60%;
                    }

                    .btn {
                        width: 35%;
                        min-width: 120px;
                        margin-left: 5%;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-dialog.dialog-adding-credit-card {
        .app-dialog-container {
            .app-dialog-body {
                padding: 64px 24px;
            }
        }
    }
}
</style>
